import React from 'react';
import BuyerAccount from '@/components/organisms/Buyer/Account';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import AuthenticationCheck from '@/components/organisms/AuthenticationCheck';

const BuyerAccountPage = () => {
  return (
    <AuthenticationCheck>
      <Wrapper>
        <BuyerHeader displayPattern="inquiry" />
        <BuyerAccount isEditMode={false} />
        <BuyerFooter />
      </Wrapper>
    </AuthenticationCheck>
  );
};

export default withInitializeApp(BuyerAccountPage);
